import React, {Component} from 'react';
import './imageUploader.scss';
import Cropper from 'react-cropper';
import heic2any from 'heic2any';
import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  Input,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  initSendImage,
  sendGalleryImage,
  sendProfileImage,
  sendUserImage,
} from './store/action';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {translate} from 'react-admin';

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      cropResult: null,
      imageType: null,
    };
  }

  onChange = async e => {
    e.preventDefault();
    this.setState({
      sizeError: false,
    });
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const file = files[0];

    if (file.size > 5 * 1024 * 1024) {
      e.target.value = '';
      this.setState({
        sizeError: true,
      });
      return;
    }

    this.setState({
      imageType: file.type,
    });
    this.props.initSendImage();
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({src: reader.result});
    };

    if (files[0] && files[0].name.toLocaleLowerCase().includes('.heic')) {
      // get image as blob url
      let blobURL = URL.createObjectURL(files[0]);
      // convert "fetch" the new blob url
      let blobRes = await fetch(blobURL);
      // convert response to blob
      let blob = await blobRes.blob();
      // convert to JPEG - response is blob
      let conversionResult = await heic2any({
        blob: blob,
        toType: 'image/jpeg',
        quality: 1,
      });

      reader.readAsDataURL(conversionResult);
    } else {
      reader.readAsDataURL(files[0]);
    }
  };

  cropImage = () => {
    const {
      type,
      sendProfileImage,
      sendGalleryImage,
      sendUserImage,
      data,
    } = this.props;

    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    const cropResult = this.cropper
      .getCroppedCanvas()
      .toDataURL(this.state.imageType);

    this.setState({
      cropResult: cropResult,
    });

    switch (type) {
      case 'profile':
        sendProfileImage({
          imageBase64: cropResult,
        });
        break;
      case 'user':
        sendUserImage(data.id, cropResult);
        break;
      default:
        sendGalleryImage({
          image: cropResult,
        });
    }
  };

  render() {
    const {isUpdated, translate} = this.props;

    return (
      <Card style={{textAlign: 'left'}}>
        <CardContent>
          <Typography gutterBottom variant="headline" component="h1">
            {translate('custom.components.image_uploader.label')}
          </Typography>
        </CardContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                width: 400 + 'px',
                maxWidth: 400 + 'px',
                height: 300 + 'px',
              }}>
              <Input
                type="file"
                onChange={this.onChange}
                inputProps={{accept: 'image/gif,image/png,image/jpeg,.heic'}}
              />
              {this.state.sizeError && (
                <FormHelperText style={{color: '#f44336'}}>
                  {translate('custom.components.image_uploader.sizeLabel')}
                </FormHelperText>
              )}
              <Cropper
                style={{height: 300 + 'px', width: 400 + 'px'}}
                aspectRatio={1}
                preview=".img-preview"
                guides={false}
                zoomable={false}
                src={this.state.src}
                ref={cropper => {
                  this.cropper = cropper;
                }}
                responsive={true}
              />
            </div>
            {this.state.src ? (
              <div style={{paddingTop: 50 + 'px'}}>
                {isUpdated === true && (
                  <Paper
                    elevation={1}
                    style={{
                      backgroundColor: '#8cc97d',
                      paddingTop: 1 + 'em',
                      paddingBottom: 1 + 'em',
                      textAlign: 'center',
                    }}>
                    <Typography variant="headline" component="h3">
                      {translate('custom.images.upload.success')}
                    </Typography>
                  </Paper>
                )}
                {isUpdated === 'sending' && (
                  <Paper
                    elevation={1}
                    style={{
                      backgroundColor: '#a5d5ff',
                      paddingTop: 1 + 'em',
                      paddingBottom: 1 + 'em',
                      textAlign: 'center',
                    }}>
                    <Typography variant="headline" component="h3">
                      {translate('custom.images.upload.sending')}
                    </Typography>
                  </Paper>
                )}
                {isUpdated === false && (
                  <Paper
                    elevation={1}
                    style={{
                      backgroundColor: '#f8d7da',
                      paddingTop: 1 + 'em',
                      paddingBottom: 1 + 'em',
                      textAlign: 'center',
                    }}>
                    <Typography variant="headline" component="h3">
                      {translate('custom.images.upload.failure')}
                    </Typography>
                  </Paper>
                )}
                <div className="box">
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.cropImage}
                      disabled={true === isUpdated || 'sending' === isUpdated}
                      style={{float: 'right'}}>
                      {translate(
                        'custom.components.image_uploader.buttonLabel',
                      )}
                    </Button>
                  </div>
                  <h1>
                    {translate('custom.components.image_uploader.previewLabel')}
                  </h1>
                  <div
                    className="img-preview"
                    style={{
                      width: 200 + 'px',
                      float: 'left',
                      height: 200 + 'px',
                      overflow: 'hidden',
                      border: 1 + 'px solid gray',
                      borderRadius: 100 + '%',
                    }}
                  />
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  isUpdated: state.imageReducer.isUpdated,
  profile: state.profileReducer.profile,
});

export default compose(
  translate,
  connect(mapStateToProps, {
    initSendImage,
    sendGalleryImage,
    sendProfileImage,
    sendUserImage,
  }),
)(ImageUploader);
