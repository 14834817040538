export const MACHINES_GET_ALL_REQUEST = 'MACHINES_GET_ALL_REQUEST';
export const MACHINES_GET_ALL_SUCCESS = 'MACHINES_GET_ALL_SUCCESS';
export const MACHINE_GET_ONE = 'MACHINE_GET_ONE';
export const MACHINE_GET_ONE_SUCCESS = 'MACHINE_GET_ONE_SUCCESS';
export const MACHINE_GET_ERRORS = 'MACHINE_GET_ERRORS';
export const MACHINE_GET_ERRORS_SUCCESS = 'MACHINE_GET_ERRORS_SUCCESS';
export const MACHINE_GET_ACTIVITIES = 'MACHINE_GET_ACTIVITIES';
export const MACHINE_GET_ACTIVITIES_SUCCESS = 'MACHINE_GET_ACTIVITIES_SUCCESS';
export const MACHINES_GET_COUNT_REQUEST = 'MACHINES_GET_COUNT_REQUEST';
export const MACHINES_GET_COUNT_REQUEST_SUCCESS =
  'MACHINES_GET_COUNT_REQUEST_SUCCESS';
export const MACHINES_ACTIVE_ONE_REQUEST = 'MACHINES_ACTIVE_ONE_REQUEST';
export const MACHINE_UPDATE_PROGRAM_POSITION =
  'MACHINE_UPDATE_PROGRAM_POSITION';
export const MACHINE_UPDATE_PROGRAM_POSITION_SUCCESS =
  'MACHINE_UPDATE_PROGRAM_POSITION_SUCCESS';
export const MACHINE_TYPES_GET_ALL_REQUEST = 'MACHINE_TYPES_GET_ALL_REQUEST';
export const MACHINE_TYPES_GET_ALL_SUCCESS = 'MACHINE_TYPES_GET_ALL_SUCCESS';
export const MACHINE_DELETE_ONE = 'MACHINE_DELETE_ONE';
export const MACHINE_EDIT_SETTINGS = 'MACHINE_EDIT_SETTINGS';

export const deleteOneMachine = data => ({
  type: MACHINE_DELETE_ONE,
  payload: data,
});

export const editSettings = data => ({
  type: MACHINE_EDIT_SETTINGS,
  payload: data,
});

export const getMachines = data => ({
  type: MACHINES_GET_ALL_REQUEST,
  payload: data,
});

export const getOneMachine = data => ({
  type: MACHINE_GET_ONE,
  payload: data,
});

export const getMachineErrors = data => ({
  type: MACHINE_GET_ERRORS,
  payload: data,
});

export const getMachineActivities = data => ({
  type: MACHINE_GET_ACTIVITIES,
  payload: data,
});

export const getCountMachines = data => ({
  type: MACHINES_GET_COUNT_REQUEST,
  payload: data,
});

export const activeMachine = data => ({
  type: MACHINES_ACTIVE_ONE_REQUEST,
  payload: data,
});

export const machineUpdateProgramPosition = data => ({
  type: MACHINE_UPDATE_PROGRAM_POSITION,
  payload: data,
});

export const getMachineTypes = () => ({
  type: MACHINE_TYPES_GET_ALL_REQUEST,
});
